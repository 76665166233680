import { useI18next, Link, useTranslation } from "gatsby-plugin-react-i18next";
import { useEffect } from "react";
import { setSitePerLanguage } from '../components/Nav/CustomNav';

const LangRedirect = (props) => {
  useEffect(() => {
    setSitePerLanguage(props.lang);
  }, [props.lang]);

  return null;
}

export default LangRedirect;